<template>
  <div v-if="sections">
    <div v-for="(section, sKey) in sections" :key="sKey" class="mb-4">
      <h5 class="title is-5 section-title mb-2" v-if="section.title">
        {{ section.title }}
        <span
          class="subtitle"
          v-if="section.subtitle && section.subtitle.value"
        >
          <span
            class="timestamp"
            v-if="section.subtitle.type === CardFieldType.log"
          >
            - <span class="user">{{ section.subtitle.value.by }}</span> op
            <span class="date">{{
              formatDateTime(section.subtitle.value.at)
            }}</span>
          </span>
          <!-- The "text" option may not actually be a timestamp but it will be treated with the same styling. -->
          <span
            class="timestamp"
            v-else-if="section.subtitle.type === CardFieldType.text"
          >
            - {{ section.subtitle.value }}
          </span>
        </span>
      </h5>
      <span
        v-if="section.placeholder && !section.columns"
        class="is-italic has-text-weight-light"
      >
        {{ section.placeholder }}
      </span>
      <div class="columns" v-else>
        <div
          class="column"
          v-for="(column, cKey) in section.columns"
          :key="cKey"
        >
          <div v-for="(field, fKey) in column.fields" :key="fKey">
            <div
              class="label-row"
              :class="[
                field.inline
                  ? 'is-flex-direction-row'
                  : 'is-flex-direction-column',
              ]"
            >
              <span
                class="label"
                :class="
                  field.highlight ? 'highlight-' + field.highlight : undefined
                "
                >{{ field.label
                }}<span v-if="field.inline" class="mr-1">:</span>
              </span>

              <span
                v-if="
                  !field.value ||
                  (Array.isArray(field.value) && field.value.length === 0)
                "
                >-</span
              >
              <template v-else>
                <ul v-if="field.type === CardFieldType.list">
                  <li v-for="(value, key) in field.value" :key="key">
                    {{ value }}
                  </li>
                </ul>
                <span
                  :class="
                    field.highlight ? 'highlight-' + field.highlight : undefined
                  "
                  v-else
                  >{{ fieldValue(field) }}
                </span>
                <span
                  class="copy-button ml-1"
                  v-if="field.copyable"
                  :title="$t('copy_to_clipboard.action')"
                  @click="copyFieldToClipboard(field)"
                >
                  <b-icon icon="copy" custom-class="far"></b-icon>
                </span>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
/* eslint vue/multi-word-component-names: 0 */
import { CardFieldType, Field, Section } from "@/types/sections";
import { formatDate, formatDateTime } from "@/filters/date";
import { ToastProgrammatic as Toast } from "buefy";
import i18n from "@/i18n";

defineProps<{
  sections: Section[];
}>();

function fieldValue(field: Field) {
  switch (field.type) {
    case CardFieldType.datetime:
      return formatDateTime(field.value as string);
    case CardFieldType.date:
      return formatDate(field.value as string);
    default:
      return field.value;
  }
}

async function copyFieldToClipboard(field: Field) {
  if (!navigator.clipboard) {
    return;
  }

  const v = fieldValue(field);
  try {
    await navigator.clipboard.writeText(
      typeof v === "string" ? v : v.toString()
    );
    Toast.open({
      message: i18n.t("copy_to_clipboard.success").toString(),
      type: "is-success",
    });
  } catch (e) {
    Toast.open({
      message: i18n.t("copy_to_clipboard.error").toString(),
      type: "is-danger",
    });
  }
}
</script>
<style lang="scss" scoped>
.subtitle {
  font-size: 12px;
  color: #6a6a6a;
}

.label-row {
  display: flex;
  // flex-direction: row;
  margin-bottom: 0.5em;

  label {
    margin-bottom: 0;
  }

  .copy-button {
    // Use visibility: hidden rather than display: none
    // to avoid elements jumping around when the icon shows up.
    visibility: hidden;
    cursor: pointer;
  }

  &:hover {
    .copy-button {
      visibility: visible;
    }
  }
}

.label-column {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;

  label {
    margin-bottom: 0;
  }
}

.field-label {
  text-align: left !important;
  @media screen and (max-width: 768px) {
    margin-bottom: 0 !important;
  }
}

ul {
  list-style: inside;
}

.timestamp {
  color: #a5a5a5;
  font-weight: 500;
}
</style>
