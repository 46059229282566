/* eslint-disable */
export const resizeTextarea = {
  inserted: function (el: HTMLElement) {
    const textarea = el.children[0];
    const height = textarea.scrollHeight - 1.5;
    textarea.setAttribute(
      "style",
      // "height:" + textarea.scrollHeight + "px;overflow-y:hidden;"
      "height:" + height + "px;overflow-y:hidden;"
    );
    textarea.addEventListener("input", OnInput, false);
    function OnInput(this: any) {
      const textarea: HTMLElement = this;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight - 1.5 + "px";
    }
  },
};
