<template>
  <div>
    <div class="is-flex is-flex-wrap-wrap" v-if="videos.length > 0">
      <div
        class="is-flex is-flex-direction-column is-align-items-center m-4"
        v-for="(video, index) in videos"
        :key="index"
      >
        <img :src="getImgUrl(video)" />
        {{ video.title[locale] }}
      </div>
    </div>
    <div v-else>
      {{ $t("anesthesia_technique_list.no_techniques_chosen_message") }}
    </div>
  </div>
</template>
<script lang="ts">
import { AnesthesiaVideo } from "@/types/anesthesiaTechniques";
import Vue from "vue";
import i18n from "@/i18n";
export default Vue.extend({
  name: "VideoList",
  props: {
    videos: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getImgUrl(video: AnesthesiaVideo) {
      if (video && video.image) {
        return require("@/assets/images/" + video.image);
      }
    },
  },
  computed: {
    locale() {
      return i18n.locale || "nl";
    },
  },
});
</script>
<style lang="scss" scoped>
img {
  height: 153px;
}
</style>
