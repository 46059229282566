<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <h1 class="modal-card-title">{{ title }}</h1>
      <button type="button" class="delete" @click="$parent.close()" />
    </header>
    <section class="modal-card-body has-text-weight-normal is-size-6 p-0">
      <div class="content">
        <b-message type="is-info" has-icon>
          <p v-html="info"></p>
        </b-message>
        <div class="code monospace bold">
          <span>{{ passcode }}</span>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ScreeningPasscodeModal",
  props: {
    passcode: {
      type: Number,
    },
    title: {
      type: String,
    },
    info: {
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.content {
  padding: 2rem;

  .code {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-size: 3rem;
  }

  .bold {
    font-weight: 800;
  }

  .monospace {
    font-family: monospace;
    white-space: pre;
  }
}
</style>
