var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.sections)?_c('div',_vm._l((_vm.sections),function(section,sKey){return _c('div',{key:sKey,staticClass:"mb-4"},[(section.title)?_c('h5',{staticClass:"title is-5 section-title mb-2"},[_vm._v(" "+_vm._s(section.title)+" "),(section.subtitle && section.subtitle.value)?_c('span',{staticClass:"subtitle"},[(section.subtitle.type === _setup.CardFieldType.log)?_c('span',{staticClass:"timestamp"},[_vm._v(" - "),_c('span',{staticClass:"user"},[_vm._v(_vm._s(section.subtitle.value.by))]),_vm._v(" op "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(_setup.formatDateTime(section.subtitle.value.at)))])]):(section.subtitle.type === _setup.CardFieldType.text)?_c('span',{staticClass:"timestamp"},[_vm._v(" - "+_vm._s(section.subtitle.value)+" ")]):_vm._e()]):_vm._e()]):_vm._e(),(section.placeholder && !section.columns)?_c('span',{staticClass:"is-italic has-text-weight-light"},[_vm._v(" "+_vm._s(section.placeholder)+" ")]):_c('div',{staticClass:"columns"},_vm._l((section.columns),function(column,cKey){return _c('div',{key:cKey,staticClass:"column"},_vm._l((column.fields),function(field,fKey){return _c('div',{key:fKey},[_c('div',{staticClass:"label-row",class:[
              field.inline
                ? 'is-flex-direction-row'
                : 'is-flex-direction-column',
            ]},[_c('span',{staticClass:"label",class:field.highlight ? 'highlight-' + field.highlight : undefined},[_vm._v(_vm._s(field.label)),(field.inline)?_c('span',{staticClass:"mr-1"},[_vm._v(":")]):_vm._e()]),(
                !field.value ||
                (Array.isArray(field.value) && field.value.length === 0)
              )?_c('span',[_vm._v("-")]):[(field.type === _setup.CardFieldType.list)?_c('ul',_vm._l((field.value),function(value,key){return _c('li',{key:key},[_vm._v(" "+_vm._s(value)+" ")])}),0):_c('span',{class:field.highlight ? 'highlight-' + field.highlight : undefined},[_vm._v(_vm._s(_setup.fieldValue(field))+" ")]),(field.copyable)?_c('span',{staticClass:"copy-button ml-1",attrs:{"title":_vm.$t('copy_to_clipboard.action')},on:{"click":function($event){return _setup.copyFieldToClipboard(field)}}},[_c('b-icon',{attrs:{"icon":"copy","custom-class":"far"}})],1):_vm._e()]],2)])}),0)}),0)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }