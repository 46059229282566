<template>
  <div class="loader-container">
    <!-- Use the same spinner as the patient client -->
    <i class="fal fa-spinner fa-3x fa-spin"></i>
  </div>
</template>
<script setup lang="ts">
// Handles a login to the clinic interface of the patient client
// This works by sending a single use auth token to a known
// page in the patient client, which it then uses to log in.
// To prevent a CSRF attack (which could log the user into
// an attacker's account, not the greatest security risk here
// but still something we'd like to prevent) we need to be sure
// that the login was actually initiated by our user. We do this
// by first having the patient client generate a state, which
// it stores in session storage. To perform the full log in,
// the patient client must be passed this state alongside the token.
// The entire flow (starting from this page) is as follows:
// - We redirect to patient-client/clinic-gateway. Note that we could also
//   start there directly, it doesn't matter.
// - The patient client gateway creates a state and stores it in session
//   storage. It then redirects back to this page including the value
//   of the state variable.
// - We generate a short lived single use auth token, and redirect back
//   to the patient client, passing the token and the state.
// - If the state matches the stored state, the patient client (through
//   the patient service -> admin service) will exchange the token for
//   an admin service token, and turn that into a login token for the
//   clinic interface.
//
// There is one piece of special behavior included here: by visiting this
// page with a handle (admin-client/clinic/login-handle) the authentication
// handler in router/index.ts knows to redirect not to the standard login
// page, but to the OpenID Connect login flow.
import { onMounted } from "vue";
import { useRoute } from "vue-router/composables";
import { getClinicToken } from "@/api";

// Resolves the patient client URL. Strictly speaking this is part
// of tenant settings, and we don't have direct access to it as a
// result - but it is currently always the same as the admin URL,
// except it starts with 'epos' instead of 'admin'. We can add it
// to the JWT if this ever becomes an issue.
function resolvePatientClientUrl() {
  const host = window.location.hostname;
  if (host === "localhost") {
    return "http://localhost:8001";
  }

  if (host.startsWith("admin.")) {
    return "https://epos." + host.substring(6);
  }

  throw new Error("Unable to resolve patient client URL for hostname:" + host);
}

const patientClientUrl = resolvePatientClientUrl();
const patientClinicGatewayUrl = patientClientUrl + "/clinic-gateway";
const route = useRoute();

onMounted(async () => {
  const hash = (route.hash || "#").substring(1);
  const params = new URLSearchParams(hash);
  const state = params.get("state");
  if (!state) {
    // Let the patient client initiate the login to set a session state
    window.location.replace(patientClinicGatewayUrl);
    return;
  }

  const token = await getClinicToken();
  if (sessionStorage.getItem("login_for_clinic")) {
    // If we logged in specifically for the clinic page,
    // log out before leaving.
    sessionStorage.removeItem("login_for_clinic");
    sessionStorage.removeItem("token");
  }

  window.location.replace(
    patientClinicGatewayUrl + `#state=${state}&token=${token}`
  );
});
</script>
<style scoped lang="scss">
.loader-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
}
</style>
