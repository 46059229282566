<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <h1 class="modal-card-title">
        {{ $t("screening_history_modal.title") }}
      </h1>
      <button type="button" class="delete" @click="$parent.close()" />
    </header>
    <section class="modal-card-body has-text-weight-normal is-size-6 p-0">
      <b-table
        class="history-table"
        :mobile-cards="false"
        :data="list"
        :row-class="() => 'indent-table'"
      >
        <b-table-column
          :label="$t('screening_history_modal.date').toString()"
          field="date"
          v-slot="props"
        >
          {{ formatDateTime(props.row.timestamp) }}
        </b-table-column>
        <b-table-column
          :label="$t('screening_history_modal.action').toString()"
          field="action"
          v-slot="props"
        >
          {{ $t("screening_history_modal.change_action." + props.row.action) }}
        </b-table-column>
        <b-table-column
          :label="$t('screening_history_modal.old').toString()"
          field="old"
          v-slot="props"
        >
          <inline-text-presentation
            v-if="props.row.old"
            :label="props.row.old.label"
            :value="props.row.old.value"
            :class="
              props.row.old.highlight
                ? 'highlight-' + props.row.old.highlight
                : undefined
            "
          />
        </b-table-column>
        <b-table-column
          :label="$t('screening_history_modal.new').toString()"
          field="new"
          v-slot="props"
        >
          <inline-text-presentation
            v-if="props.row.new"
            :label="props.row.new.label"
            :value="props.row.new.value"
            :class="
              props.row.new.highlight
                ? 'highlight-' + props.row.new.highlight
                : undefined
            "
          />
        </b-table-column>
        <b-table-column
          :label="$t('screening_history_modal.user').toString()"
          field="user"
          v-slot="props"
        >
          {{
            props.row.user_name
              ? props.row.user_name
              : $t("screening_history_modal.unknown_user")
          }}
        </b-table-column>
      </b-table>
    </section>
    <footer class="modal-card-foot"></footer>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { ReportChange, ReportChangeActionType } from "@/types/report";
import InlineTextPresentation from "./InlineTextPresentation.vue";
import { formatDateTime } from "../filters/date";

export default Vue.extend({
  methods: { formatDateTime },
  components: { InlineTextPresentation },
  name: "ScreeningHistoryModal",

  props: {
    changes: {
      type: Array as PropType<ReportChange[]>,
    },
  },
  computed: {
    list() {
      return [...this.changes].reverse();
    },
  },
  data() {
    return {
      ReportChangeActionType,
    };
  },
});
</script>
<style lang="scss" scoped>
:deep(.history-table) {
  thead {
    th:first-of-type {
      padding-left: 1.25em;
    }
  }

  .indent-table > td:first-of-type {
    padding-left: 1.25em;
  }
}
</style>
