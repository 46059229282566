import Vue from "vue";

import i18n from "./i18n";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";

import "@/styles/global.scss";
import "@/filters/date";

import "@fortawesome/fontawesome-pro/css/all.css";
import "@fortawesome/fontawesome-pro/js/all.js";

import "./validation";
import { resizeTextarea } from "./directives/resizeTextArea";
import PortalVue from "portal-vue";

import "@/utils/logger";
import Clipboard from "v-clipboard";

import { titleizeFilter, uppercaseFilter } from "@/utils/templateFilters";

Vue.filter("uppercase", uppercaseFilter);
Vue.filter("titleize", titleizeFilter);

Vue.use(Clipboard);
Vue.config.productionTip = false;

// Custom HTML5 components
Vue.config.ignoredElements = ["dyte-meeting"];

Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultNotificationDuration: 5000,
  defaultNotificationPosition: "is-top-center",
})
  .use(PortalVue)
  .directive("resizeTextarea", resizeTextarea);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
