<template>
  <div class="authenticator">
    <!-- Current password + Disable Authenticator button -->
    <b-field
      grouped
      :label="$t('user_password_update.current_password').toString()"
      :message="passwordError"
      v-if="user.authenticator_enabled"
      :type="passwordError ? 'is-danger' : ''"
    >
      <b-input
        type="password"
        expanded
        :placeholder="$t('user_password_update.current_password').toString()"
        v-model="currentPassword"
        @keyup.enter.native="disableAuthenticator"
      ></b-input>
      <b-button
        type="is-danger is-light"
        :disabled="!currentPassword"
        @click="disableAuthenticator"
      >
        <span
          >{{ $t("authenticator_settings.deactivate_app") }}
          <i class="fas fa-trash"></i
        ></span>
      </b-button>
    </b-field>

    <!-- Current password + Enable Authenticator button-->
    <b-field
      grouped
      :label="$t('user_password_update.current_password').toString()"
      :message="passwordError"
      v-if="!qrUrl && !user.authenticator_enabled"
      :type="passwordError ? 'is-danger' : ''"
    >
      <b-input
        type="password"
        expanded
        :placeholder="$t('user_password_update.current_password').toString()"
        v-model="currentPassword"
        @keyup.enter.native="getQRCode"
      ></b-input>

      <b-button
        type="is-primary is-light"
        @click="getQRCode"
        outlined
        :disabled="!currentPassword"
        ><span
          >{{ $t("authenticator_settings.activate_app") }}
          <i class="fas fa-mobile-alt"></i
        ></span>
      </b-button>
    </b-field>

    <div class="qr-code">
      <!--
        Don't remove the keys on the views below. There's a race condition
        where the fontawesome svg ends up in the wrong div, removing
        any future elements. The keys seem to prevent this.
      -->
      <div
        v-if="!loadingQR && qrUrl"
        class="qr-container mb-2"
        key="qrcode-container"
      >
        <canvas id="2fa-qrcode" ref="qrCanvas" />
      </div>

      <div v-if="loadingQR" class="qr-loader mb-2" key="loader-container">
        <i class="fas fa-circle-notch fa-spin fa-4x"></i>
      </div>

      <div v-if="qrUrl" class="details">
        <b-button
          type="is-primary"
          :disabled="loadingQR"
          @click="getQRCode"
          outlined
          ><span
            >{{ $t("authenticator_settings.new_qr_code") }}
            <i class="fas fa-sync-alt"></i
          ></span>
        </b-button>

        <div class="message mt-4" v-if="message">
          <div class="message-body">{{ message }}</div>
        </div>

        <p class="mt-2">
          {{ $t("authenticator_settings.scan_qr_code_instructions") }}
        </p>

        <div class="field">
          <b-input
            type="text"
            required
            v-model="otpCode"
            class="form-control"
            id="otpcode"
            aria-describedby="otpcode"
            :placeholder="$t('authenticator_settings.verification_code')"
            @keyup.enter.native="enableAuthenticator"
          />
        </div>

        <b-button
          type="is-submit"
          class="btn btn-light pt-2"
          @click="enableAuthenticator"
          :disabled="!otpCode || otpCode.length !== 6"
          ><span>
            {{ $t("authenticator_settings.send_code") }}
          </span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { User } from "@/types/users";
import { setJWT } from "@/utils/authUtil";
import QRCode from "qrcode";

import {
  generateSecret,
  enableAuthenticator,
  disableAuthenticator,
} from "@/api";
import i18n from "@/i18n";
import { AxiosError } from "axios";

export default Vue.extend({
  name: "AuthenticatorSettings",
  props: {
    user: {
      type: Object as PropType<User>,
    },
  },
  data() {
    return {
      loadingQR: false,
      message: null as string | null,
      otpCode: null as string | null,

      // URL that should be in the QR code that the authenticator
      // can scan.
      qrUrl: null as string | null,

      currentPassword: "",
      passwordError: null as string | null,
    };
  },
  watch: {
    qrUrl(url) {
      this.$nextTick(() => {
        const canvas = this.$refs.qrCanvas;
        if (!canvas || !url) {
          return;
        }

        QRCode.toCanvas(canvas, url, { width: 250 }, function (error) {
          // eslint-disable-next-line no-console
          console.error(`Could not write code to canvas: ${error}.`);
        });
      });
    },
  },
  methods: {
    setIncorrectPassword(error: any) {
      if ((error as AxiosError).response?.status === 422) {
        this.passwordError = this.$t(
          "profile_page.incorrect_password"
        ).toString();
      }
    },

    async getQRCode() {
      this.loadingQR = true;
      this.passwordError = null;

      try {
        const response = await generateSecret(this.currentPassword!);
        this.qrUrl = response.data;
      } catch (e) {
        this.setIncorrectPassword(e);
      } finally {
        this.loadingQR = false;
      }
    },
    async enableAuthenticator() {
      if (!this.otpCode) {
        return;
      }

      this.message = null;

      try {
        const { data } = await enableAuthenticator(this.otpCode);
        this.otpCode = null;
        this.currentPassword = "";
        this.qrUrl = null;
        setJWT(data);
      } catch {
        this.message = i18n
          .t("authenticator_settings.invalid_code_message")
          .toString();
      }
    },
    async disableAuthenticator() {
      try {
        const response = await disableAuthenticator(this.currentPassword);
        setJWT(response.data);
        this.qrUrl = null;
        this.currentPassword = "";
        this.passwordError = null;
      } catch (error) {
        this.setIncorrectPassword(error);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.qr-loader {
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-container {
  width: 250px;
  height: 250px;
}
</style>
